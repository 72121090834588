import { http } from '@/api';

// 合成接口信息
export function drawconfig(params) {
  return http.get('/v1/draw/drawconfig', params);
}

// 积分抽奖
export function scoresDraw(params) {
  return http.post('/v1/scores/draw', params);
}

// 获取我的积分
export function getuserscores(params) {
  return http.get('/v1/scores/getuserscores', params);
}

// 中奖记录
export function drawlist(params) {
  return http.get('/v1/draw/drawlist', params);
}

// 兑换列表
export function getexchangeList(params) {
  return http.get('/v1/scores/getexchangeitems', params);
}

// 兑换接口
export function scoresexchange(params) {
  return http.post('/v1/scores/scoresexchange', params);
}

// 兑换记录
export function exchangelist(params) {
  return http.get('/v1/scores/exchangelist', params);
}

// 填写收货信息
export function setexpressinfo(params) {
  return http.post('/v1/scores/setexpressinfo', params);
}

// 获取用户签到数据信息
export function getusersign(params) {
  return http.get('/v1/user/getusersign', params);
}

// 用户签到接口
export function sign(params) {
  return http.post('/v1/user/sign', params);
}

// 获取足球杯抽奖信息列表
export function getfootballawardlist(params) {
  return http.get('/v2/football/getfootballawardlist', params);
}

// football - 抽奖接口信息
export function drawfootball(params) {
  return http.post('/v2/football/drawfootball', params);
}

// football - 获取中奖记录
export function getawardlog(params) {
  return http.get('/v2/football/getawardlog', params);
}

// 获取用户详情地址
export function getuseraddr(params) {
  return http.get('/v2/football/getuseraddr', params);
}

// football - 新增/修改发货地址
export function addoreditaddr(params) {
  return http.post('/v2/football/addoreditaddr', params);
}








